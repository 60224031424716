@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */

/**
 * Remove the margin in all browsers.
 */

body {
  margin: 0;
  font-family: 'Nunito', sans-serif !important;
  overflow-x: hidden;
  
}

/**
 * Render the `main` element consistently in IE.
 */

main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h3{
  font-weight: bold !important;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}
* {
  font-family: "Roboto", sans-serif !important;
}
.reg-container {
  width: 100%;
  margin: 0;
  margin-bottom:90px;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  padding:3%;

}


.reg-container form {
  padding: 20px 10%;
}
.btnSubmit {
  display: block;
  width: 60%;
  margin: 20px auto;
  border-radius: 100px;
  padding: 1.5%;
  border: none;
  cursor: pointer;
  font-weight: 900 !important;

  height: 50px;
  text-transform: uppercase;
  transition: 0.1s ease-in;
}
.btnSubmit:hover {
  background-color: #fff !important;
  color: #1b6b2f !important;
  border: 2px solid #1b6b2f !important;
}
.reg-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #1b6b2f;
}

.reg-form-1 .ForgetPwd {
  color: #1b6b2f;
  font-weight: 600;
  text-decoration: none;
}

.fm-top {
  position: fixed;
  top: -30px;
  right: -30px;
  width: 200px;
  height: auto;
}
.logo {
  display: block;
  margin: 20px auto;
  width: 180px;
  height: auto;
}
.fm-bottom {
  position: fixed;
  bottom: -30px;
  left: -30px;
  width: 200px;
  height: auto;
}

.caliinfo {
  width: 170px;

  position: fixed;
  top: 10px;
  left: 20px;
}

.hash {
  color: #1b6b2f;
  font-weight: bold;
  font-size: 16px;
}
.title {
  text-align: left;
}

.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  background-color: #113b81;
  height: 70px;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

.footer a {
  display: block;
  color: #fff !important;
}

.footer .phone {
  font-weight: bold;
  font-size: 20px;
}
.footer .email {
  color: #fff !important;
}

.modalT {
  color:#113B81;
}
.modalP  {
  font-weight: 400;

}
.form label{

  font-weight: bold;


}

.send{
  
  display: -webkit-flex;
  
  display: flex;
  margin-top:30px;
  -webkit-justify-content:space-between;
          justify-content:space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #113B81;
  color: #fff !important;
  border :none;
  border-radius:2px;

}
@media (max-width: 550px) {
  .fm-top {
    width: 120px !important;
    top: -30px;
    right: -30px;
  }

  .fm-bottom {
    width: 120px !important;
    bottom: -30px;
    left: -30px;
  }

  .reg-form-1 {
    box-shadow: none !important;
  }

  .caliinfo {
    top: -5px;
    left: 10px;
    width: 130px !important;
  }
}

@media (max-width: 768px) {
  .fm-top {
    width: 140px;
    top: -30px;
    right: -30px;
  }

  .fm-bottom {
    width: 140px;
    bottom: -30px;
    left: -30px;
  }

  .caliinfo {
    top: -5px;
    left: 10px;
    width: 150px;
  }
}

.background{

    position: absolute;
    opacity: 0.9;
    background-size: cover;
    z-index: 0;
    border-radius:5px;

    
}
.ofContainer{
    padding: 0;
    margin: 0;
    max-height: 200px;
    background-color: black;
    width: 100%;
    height:100%;
    border-radius:5px;
  
}
.info{
    width:100%;
    display:block;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    padding: 3% 6%;
    background-size: cover;
}

.btnOffert{
    background-color:transparent;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    text-transform: uppercase;
    transition: 1s ease-in-out;
}

.btnOffert:hover{
    background-color:#fff;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    text-transform: uppercase;
    color :#113B81;
}

.info h3{
    font-size: 18px;
    color: #fff;
}

.info p{
    font-size: 10px;
    font-weight: 400;
    color: #fff;
}

.info button{
    color: #fff;
    border: 1px solid #fff;
    height:40px;
    padding:5px 5px;
    width: 100%;
}

.sc-bdnxRM{
font-size:px !important;
background-color: transparent !important;
border: none !important;
box-shadow: none !important;
display: none;
color: #fff !important;

}

.rec-arrow-right{
    right: 0;
    margin-right: 15px;
    z-index: 1;


}


.rec-arrow-left{
    left: 0;
    z-index: 1;
    margin-left: 15px;
}

.rec-dot_active{
    background-color:#113B81 !important;
    border:none !important;
}

.rec-dot{
    background-color:#fff ;
    border:1px solid #113B81 !important;
    box-shadow: none !important;
}
.c{
    color :#113B81;
}

.w{
    color :#22e010;
}

.btn{ 
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.27);
}

.title{
    text-transform: none !important;
    text-indent: none !important;
    letter-spacing:0;
}

.time{
   font-weight: 300;
    color: #8A8A8A;
}

