.c{
    color :#113B81;
}

.w{
    color :#22e010;
}

.btn{
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.27); 
    box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.27);
}

.title{
    text-transform: none !important;
    text-indent: none !important;
    letter-spacing:0;
}

.time{
   font-weight: 300;
    color: #8A8A8A;
}
