.background{

    position: absolute;
    opacity: 0.9;
    background-size: cover;
    z-index: 0;
    border-radius:5px;

    
}
.ofContainer{
    padding: 0;
    margin: 0;
    max-height: 200px;
    background-color: black;
    width: 100%;
    height:100%;
    border-radius:5px;
  
}
.info{
    width:100%;
    display:block;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    padding: 3% 6%;
    background-size: cover;
}

.btnOffert{
    background-color:transparent;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    text-transform: uppercase;
    transition: 1s ease-in-out;
}

.btnOffert:hover{
    background-color:#fff;
    border: none !important;
    border-bottom: 1px solid #fff !important;
    text-transform: uppercase;
    color :#113B81;
}

.info h3{
    font-size: 18px;
    color: #fff;
}

.info p{
    font-size: 10px;
    font-weight: 400;
    color: #fff;
}

.info button{
    color: #fff;
    border: 1px solid #fff;
    height:40px;
    padding:5px 5px;
    width: 100%;
}

.sc-bdnxRM{
font-size:px !important;
background-color: transparent !important;
border: none !important;
box-shadow: none !important;
display: none;
color: #fff !important;

}

.rec-arrow-right{
    right: 0;
    margin-right: 15px;
    z-index: 1;


}


.rec-arrow-left{
    left: 0;
    z-index: 1;
    margin-left: 15px;
}

.rec-dot_active{
    background-color:#113B81 !important;
    border:none !important;
}

.rec-dot{
    background-color:#fff ;
    border:1px solid #113B81 !important;
    box-shadow: none !important;
}