@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  font-family: "Roboto", sans-serif !important;
}
.reg-container {
  width: 100%;
  margin: 0;
  margin-bottom:90px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  padding:3%;

}


.reg-container form {
  padding: 20px 10%;
}
.btnSubmit {
  display: block;
  width: 60%;
  margin: 20px auto;
  border-radius: 100px;
  padding: 1.5%;
  border: none;
  cursor: pointer;
  font-weight: 900 !important;

  height: 50px;
  text-transform: uppercase;
  transition: 0.1s ease-in;
}
.btnSubmit:hover {
  background-color: #fff !important;
  color: #1b6b2f !important;
  border: 2px solid #1b6b2f !important;
}
.reg-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #1b6b2f;
}

.reg-form-1 .ForgetPwd {
  color: #1b6b2f;
  font-weight: 600;
  text-decoration: none;
}

.fm-top {
  position: fixed;
  top: -30px;
  right: -30px;
  width: 200px;
  height: auto;
}
.logo {
  display: block;
  margin: 20px auto;
  width: 180px;
  height: auto;
}
.fm-bottom {
  position: fixed;
  bottom: -30px;
  left: -30px;
  width: 200px;
  height: auto;
}

.caliinfo {
  width: 170px;

  position: fixed;
  top: 10px;
  left: 20px;
}

.hash {
  color: #1b6b2f;
  font-weight: bold;
  font-size: 16px;
}
.title {
  text-align: left;
}

.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  background-color: #113b81;
  height: 70px;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

.footer a {
  display: block;
  color: #fff !important;
}

.footer .phone {
  font-weight: bold;
  font-size: 20px;
}
.footer .email {
  color: #fff !important;
}

.modalT {
  color:#113B81;
}
.modalP  {
  font-weight: 400;

}
.form label{

  font-weight: bold;


}

.send{
  
  display: flex;
  margin-top:30px;
  justify-content:space-between;
  align-items: center;
  background-color: #113B81;
  color: #fff !important;
  border :none;
  border-radius:2px;

}
@media (max-width: 550px) {
  .fm-top {
    width: 120px !important;
    top: -30px;
    right: -30px;
  }

  .fm-bottom {
    width: 120px !important;
    bottom: -30px;
    left: -30px;
  }

  .reg-form-1 {
    box-shadow: none !important;
  }

  .caliinfo {
    top: -5px;
    left: 10px;
    width: 130px !important;
  }
}

@media (max-width: 768px) {
  .fm-top {
    width: 140px;
    top: -30px;
    right: -30px;
  }

  .fm-bottom {
    width: 140px;
    bottom: -30px;
    left: -30px;
  }

  .caliinfo {
    top: -5px;
    left: 10px;
    width: 150px;
  }
}
